import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Icon from "../../components/custom-widgets/icon";

const BestBank = () => {
  const SEOTitle = "WaFd Bank Named Best Bank by Forbes & Newsweek";
  const SEODescription =
    "Find out why WaFd Bank was named Best Bank by Newsweek and Forbes in multiple states including ID, OR,  WA, NM, and more on the West Coast.";
  const SEOData = {
    title: SEOTitle,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: SEOTitle
      },
      {
        name: "description",
        content: SEODescription
      },
      {
        property: "og:title",
        content: SEOTitle
      },
      {
        property: "og:description",
        content: SEODescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/best-bank"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "America's Best Bank"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WaFd Bank Is Proud to Be Recognized and Awarded Best Bank</h1>
        <div className="row mt-5">
          <div className="col-md-6">
            <h3 className="text-success font-weight-bold">
              Working together isn't new for us. Here's how we earned this honor.
            </h3>
            <p>
              Newsweek Best Bank winners are assessed on more than 30 separate factors, covering the overall health of
              the bank, customer service performance and features, digital and branch presence, account and loan
              options, interest rate offerings and fees. Among the specific data collected: the average service charges
              collected on deposit accounts during the first quarter of 2023; average interest paid on deposit accounts
              during the first quarter of 2023; the number of complaints submitted to the Consumer Financial Protection
              Bureau and how quickly the bank responded, the kinds of loan and account products offered; mobile app
              scores; the kinds of customer service features offered; overall health of the bank as determined by
              DepositAccounts.com; and, depending on the Best Bank accolade, branch presence.
            </p>
            <p>
              Forbes partnered with market research firm Statista to survey approximately 31,000 U.S. residents, all of
              whom were asked to name and evaluate the institutions where they have a checking or savings account.
              Criteria included customer service, the quality of financial advice staff were able to offer, whether fees
              are transparent and reasonable, and the overall level of trust they inspired, among others. WaFd Bank is
              proud to have received this award!
            </p>

            <p>
              From digital options, touchless transactions and increased non-profit contributions to help our neighbors,
              we're still here for YOU. Our mission is to strengthen communities and the financial well-being of our
              clients by delivering secure, reliable, common sense banking solutions, and we're committed to building
              relationships with clients, shareholders, and the community at large. Together, we create opportunities
              that strengthen neighborhoods and the lives of those we serve.
            </p>
            <h5 className="text-success">
              When other lenders are pulling back, we're leaning in. During the pandemic, we extended a helping hand to
              thousands of our neighbors, WaFd clients or not.
            </h5>
          </div>
          <div className="col-md-6">
            <div className="row justify-content-around mb-3">
              <div className="col-6">
                <StaticImage
                  src="../../images/newsweek-3yr-lockup-600.png"
                  alt="America's Best Bank 2021, 2022 and 2023 - Newsweek"
                  placeholder="blurred"
                  loading="eager"
                  quality="100"
                  height={225}
                />
              </div>
              <div className="col-6">
                <StaticImage
                  src="../../images/forbes-logo-2024-with-shadow.png"
                  alt="WaFd Bank voted Best-in-State Bank by Forbes."
                  placeholder="blurred"
                  quality="100"
                  height={225}
                />
              </div>
            </div>
            <h3>Awarded America's Best Big Bank by Newsweek</h3>
            <ul className="mb-0">
              <li>
                <Link id="washington-best-bank-link" to="/about-us/best-bank/washington-state">
                  Best Big Bank in Washington
                </Link>
              </li>
              <li>
                <Link id="idaho-best-bank-link" to="/about-us/best-bank/idaho">
                  Best Big Bank in Idaho
                </Link>
              </li>
              <li>
                <Link id="new-mexico-best-bank-link" to="/about-us/best-bank/new-mexico">
                  Best Big Bank in New Mexico
                </Link>
              </li>
            </ul>
            <p>
              <a
                id="newsweek-site-cta"
                className="text-decoration-none"
                href="https://www.newsweek.com/rankings/americas-best-banks-2023/best-big-bank-2023"
                target="_blank"
                rel="noopener noreferrer"
              >
                Newsweek Best Big Banks 2023
                <Icon name="arrow-right" class="ml-1" />
              </a>
            </p>

            <h3>Awarded America's Best-in-State Bank by Forbes</h3>
            <ul className="mb-0">
              <li>
                <Link id="washington-best-in-state-bank-link" to="/about-us/best-bank/washington-state">
                  Best in-State in Washington
                </Link>
              </li>
              <li>
                <Link id="nevada-best-in-state-bank-link" to="/about-us/best-bank/nevada">
                  Best in-State in Nevada
                </Link>
              </li>
              <li>
                <Link id="new-mexico-best-in-state-bank-link" to="/about-us/best-bank/new-mexico">
                  Best in-State in New Mexico
                </Link>
              </li>
            </ul>
            <p>
              <a
                id="best-in-state-banks-link-forbes"
                className="text-decoration-none"
                href="https://www.forbes.com/lists/best-in-state-banks/?sh=7992502e709e"
                target="_blank"
                rel="noopener noreferrer"
              >
                Forbes Best-in-State Banks 2024
                <Icon name="arrow-right" class="ml-1" />
              </a>
            </p>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default BestBank;

